<template>
    <section class="content">
        <div class="flex justify-content-center">
            <div class="w-100 card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-xl-12 col-md-12 flex">
                            <div class="row">
                                <div class="col-auto">
                                <h4>Periode Laporan</h4>
                                </div>
                                <div class="col-md-3">
                                <div class="input-group mb-3">
                                    <input type="text" ref="daterange" class="form-control" style="border-right: 0"/>
                                    <div class="input-group-append">
                                    <div
                                        class="input-group-text"
                                        style="background-color: #fff">
                                        <span><span class="fa fa-calendar"></span></span>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mb-2"></div>
                    <div class="table-responsive">
                        <table class="table table-hover" ref="tbltrialbalance">
                        <thead>
                            <tr>
                            <th>NO. AKUN</th>
                            <th>SALDO AWAL</th>
                            <th>NAMA AKUN</th>
                            <th>DEBIT</th>
                            <th>KREDIT</th>
                            <th>SALDO</th>
                            </tr>
                        </thead>
                        <tbody @click="handleClick"></tbody>
                        <tfoot>
                        <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        </tr>
                        </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { authFetch, createTable, formatCurrency } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import Daterangepicker from "daterangepicker";
import moment from "moment";
import "daterangepicker/daterangepicker.css";

export default {
    name: "TrialBalance",
    data() {
        return {
            dt1: "",
            dt2: "",
            dateStart: "",
            dateEnd: "",
            errors: [],
            method: "",
            jurnal: {
                coa_id: '',
                coa_no: '',
                coa_name: '',
            },
            roles: "",
            role_id: "",
            coa_id: "",
        };
    },
    created: function () {
        this.roles = this.$route.meta.roles;
        console.log("load initial data", this.$route);
        this.dateStart = moment().add(-1, 'M').startOf("month").format("YYYY-MM-DD");
        this.dateEnd = moment().format("YYYY-MM-DD");
    },
    methods: {
        onPeriode() {
        this.tbl.api().ajax.reload();
        },
        getDataItem(aData)
        {
            return {
                coa_id: aData.coa_id,
                coa_no: aData.coa_no,
                coa_name: aData.coa_name,
            }
        },
        handleClick(e) {
            const er = this.$refs;
            if (e.target.matches(".link-role")) {
                // this.dt1 = moment(this.dateStart).format("DD/MM/YYYY");
                // this.dt2 = moment(this.dateEnd).format("DD/MM/YYYY");

                // var nRow = $(e.target).closest("tr").get(0);
                // var iRow = this.tbl.fnGetPosition(nRow);
                // var aData = this.tbl.fnGetData(iRow);
                // this.jurnal = this.getDataItem(aData);

                // this.coa_id = e.target.dataset.id;

                // this.tabledetail.api().ajax.reload();

                // $(er.formPreview).modal("show");
                // return false;
            }
        },
    },
    mounted() {
    const e = this.$refs;
    const self = this;
    new Daterangepicker(this.$refs.daterange, {
        startDate: moment().add(-1, 'M').startOf("month"),
        endDate: moment(),
        locale: {
            format: 'DD/MM/YYYY'
        }
        }, function (d1, d2) {
        self.dateStart = d1.format("YYYY-MM-DD");
        self.dateEnd = d2.format("YYYY-MM-DD");
        self.onPeriode();
        });
    
    this.tbl = createTable(e.tbltrialbalance, {
        title: "",
        roles: this.$route.params.roles,
        selectedRowClass: "",
        serverSide: true,
        frame: false,
        processing: true,
        language: {
            processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'},
        displayLength: 10,
        lengthChange: true,
        ajax: "/report/trialbalance",
        paramData: function (d) {
            d.dt1 = self.dateStart;
            d.dt2 = self.dateEnd;
        },
        columns: [
            { data: "coa_no" },
            { 
            data: "saldoawal", 
            class:'text-right',
            render: function(data, type, row, meta) {
                return formatCurrency(data);
            }
            },
            { data: "coa_name" },
            { 
            data: "sumdebit", 
            class:'text-right',
            render: function(data, type, row, meta) {
                return formatCurrency(data);
            }
            },
            { 
            data: "sumcredit", 
            class:'text-right',
            render: function(data, type, row, meta) {
                return formatCurrency(data);
            }
            },
            { 
            data: "sumsaldo", 
            class:'text-right',
            render: function(data, type, row, meta) {
                return formatCurrency(data);
            }
            },
        ],
        filterBy: [0,1],
        rowCallback: function (row, data) {
        },
        initComplete: function () {
            $('.loading-overlay').removeClass('show');
        },
        footerCallback:function ( row, data, start, end, display ){
        var api = this.api(), data;
            // Total over this page
            var totaldebit;
            totaldebit = 
                api
                .column(3, { page: 'current'})
                .data()
                .reduce( function (a, b) {
                    var x = parseFloat( a ) || 0;
                    var y = parseFloat( b ) || 0;
                    return x + y;
                }, 0);

            var totalcredit;
            totalcredit = api
                .column(4, { page: 'current'})
                .data()
                .reduce( function (a, b) {
                    var x = parseFloat( a ) || 0;
                    var y = parseFloat( b ) || 0;
                    return x + y;
                }, 0 );

            var totalsaldo;
            totalsaldo = api
                .column(5, { page: 'current'})
                .data()
                .reduce( function (a, b) {
                    var x = parseFloat( a ) || 0;
                    var y = parseFloat( b ) || 0;
                    return x + y;
                }, 0 );

            // Update footer
                $( api.column(3).footer() ).html(
                    'Rp '+ formatCurrency(totaldebit)
                );
                $( api.column(4).footer() ).html(
                    'Rp '+ formatCurrency(totalcredit)
                );
                $( api.column(5).footer() ).html(
                    'Rp '+ formatCurrency(totalsaldo)
                );
        }
        });
    },
}
</script>